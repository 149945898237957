.signup4-wrap {
  background: #242424 !important
}
.signup4-container {
    border-radius: 12px;
    position: relative;
    overflow: hidden;
}

.signup4-header {
    background: #1e1e1e;
    position: relative;
    padding: 35px;

    & > div {
        z-index: 2;
    }
}

.signup4-header::before{
    // background-color: rgba(3, 143, 222, 0.7);
    content: '';
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
  }

.signup4-small-image {
    height: 24px;
}
.signup-content{
    background: #1a1a1a;
}
.signup4-form {
    padding: 35px;
    width: 100%;

    h2{
        margin-bottom: 24px;
    }
}

.forgot-password{
    text-align: right;

    a{
        color: #245883;
        font-size: 16px;
        font-weight: 500;
    }
}
.forgot-password a:hover{
    color: #2b7cbf !important;
}

.signup4-button {
    text-align: center;
    .mat-raised-button {
        border-radius: 18px;
    }
}

.ml-20{
    margin-left: 20px;
}