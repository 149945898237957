/*-------------- Loading buttons --------------*/
.button-loading {
  .mat-button-wrapper {
      display: flex;
      align-items: center;
  }
}
.btn-spinner {
  width: 1em;
  height: 1em;
  background: transparent;
  border-radius: 50%;
  margin: 0 16px 0 0;
  border: 2px solid transparent;
  animation: btn-glow 1s ease infinite;;
}

@keyframes btn-glow {
  0% { box-shadow: 0 0 0 .4em darken(#777676, 10%), 0 0 0 .1em darken(#777676, 10%); transform:rotate(360deg); }
  50% { border-top-color: #777676;}
  100% { box-shadow: 0 0 0 .4em darken(#777676, 10%), 0 0 0 3.6em transparent; }
}
