/*
 *
 *   tgss - Angular Material Design Admin Template
 *
 *
*/

// // Third pirty style files
// // Sidebar Scroll
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
// // Flag icon
@import '~flag-icon-css/css/flag-icon.min.css';

// //  star-rating styles (Used in Shop app)
@import "~css-star-rating/dist/css/star-rating.min.css";
// // Data Table
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
// // Rich Text Editor
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
// // Drag and Drop
@import '~dragula/dist/dragula.css';
// // Calendar
@import '~angular-calendar/css/angular-calendar.css';

// // User tour
@import '~hopscotch/dist/css/hopscotch.min.css';
// // Page loader Topbar
@import '../../vendor/pace/pace-theme-min.css';

@import 'suneditor/dist/css/suneditor.min.css';

// // Highlightjs theme
// // @import '~highlight.js/styles/darcula.css';
// // @import '~highlight.js/styles/github.css';
@import '~highlight.js/styles/github-gist.css';


// Main Variables File
@import "scss/variables";

@import "scss/mixins/mixins";
@import "scss/colors";

@import "scss/animations";

// Open _main.scss and keep files which are required for your current layout.
@import "scss/main/main";

// Angular material theme files
@import "scss/themes/init";

@import "scss/utilities/utilities";
@import "scss/components/components";
@import "scss/palette";


@import "scss/page-layouts";

// Open _views.scss and remove unnecessary view related files
@import "scss/views/views";

//theme color changes
.tgss-dark .app-admin-container .rightside-content-hold {
    background: #242424;
}

.tgss-dark .mat-toolbar {
    background: #242424;
}

.tgss-dark .sidebar-black .navigation-hold {
    background: #242424 !important;
    border-right: 0.5px solid #ffffff5e;
}

.tgss-dark .sidebar-black .navigation-hold .branding {
    background: #242424 !important;
    border-right: 0.5px solid #ffffff5e;
}

.tgss-dark .sidebar-black .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
    border: none !important;
}

.tgss-dark .mat-dialog-container {
    background: #242424 !important;
}

.tgss-dark .sidebar-black .navigation-hold .sidenav-hold .sidenav li.open a {
    border-right: 3px solid white;
}

.tgss-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgb(248 9 64 / 51%) !important;
}

.tgss-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #f80940 !important;
}


.tgss-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #f80758 !important;
}

.tgss-dark .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #f80758 !important;
}

.tgss .mat-select-placeholder {
    letter-spacing: 0 !important;
}

// Toggle group button

.tgss-dark .mat-button-toggle-group-appearance-standard {
    // border: unset !important;
    padding: 8px;
}

.tgss-dark .mat-button-toggle-checked {
    background-color: #f80758 !important;
}



.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: unset !important;
}

mat-button-toggle-group {

    .overall,
    .suggest {
        padding-left: 16px !important;
        padding-right: 16px !important;
        line-height: 34px !important;
        border-radius: 10px !important;
        border-left: unset !important;
        background: unset !important;
    }
}

.text-pink {
    color: #f80758 !important;
}

.dark-bg {
    background: #1e1e1e !important;
}

.notify-bg {
    background: #242424 !important;
}

//common search
.common-search {
    width: 50%;
    border: none;
}

.common-search .mat-form-field-flex {
    border-radius: 15px;
    background: #1f1f1f;
}

.common-search .mat-form-field-infix {
    font-size: 14px;
    border-top: 0px;
    padding: 0px 20px;
}

.common-search .mat-form-field-wrapper {
    padding-bottom: 0px;
}

.common-search .mat-form-field-prefix span {
    position: relative;
    top: 12px;
    font-size: 15px;
    left: 8px;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.common-search .mat-form-field-infix input {
    position: relative;
    top: 4px;
}

.font_14 {
    font-size: 14px;
}

.d-flex-spbwn {
    display: flex !important;
    justify-content: space-between !important;
}

.d-flex-spbwn-center {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.s-around {
    justify-content: space-around;
}

.d-flex-center {
    display: flex;
    align-items: center;
}

.align-baseline {
    align-items: baseline;
}

mat-form-field {
    textarea {
        min-height: 50px !important;
    }
}

app-terms-conditions {
    mat-form-field {
        textarea {
            min-height: 100px !important;
        }
    }
}



// login error msg 
app-otp-login .mat-form-field-appearance-outline .mat-form-field-flex {
    align-items: center !important;
}

//table
.tgss-dark .mat-table {
    background: #1a1a1a;
}

.tgss-dark .mat-header-cell {
    color: white;
    font-size: 14px;
}

.tgss-dark .mat-cell,
.tgss-dark .mat-footer-cell {
    color: rgb(255 255 255 / 51%);
    font-size: 13px;
}

.tgss-dark tr.mat-row,
.tgss-dark tr.mat-footer-row {
    height: 36px;
}

.tgss-dark th.mat-header-cell {
    border-bottom: none;
}

.tgss-dark td .mat-chip {
    width: 87px;
    text-align: center;
}

//colors
.text-blue {
    color: #3b81f7;
}

.text-red {
    color: #ee4444;
}

.text-yellow {
    color: #f59e0b;
}

.text-grey {
    color: #808080de;
}

.text-pink {
    color: #f80758;
}

.text-green {
    color: #26a23a;
}

.bg-gray {
    background: #1e1e1e !important;
    border: 1px solid #474747;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

//card
.tgss-dark mat-card.main-card {
    background: #1e1e1e;
    border-bottom-left-radius: 17px;
    border-bottom-right-radius: 17px;
    border-top-right-radius: 17px;
}

.tgss-dark mat-card.content-card {
    background: #1a1a1a;
    border-radius: 20px;
}

.tgss-dark mat-card {
    font-family: Archivo, "Helvetica Neue", sans-serif !important;
}

.tgss-dark mat-card.advanced-filter-card {
    background: #1a1a1a;
    border-radius: 20px;
}

.tgss-dark .title {
    background: #1e1e1e;
    padding: 13px 45px 13px;
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
    position: relative;
    left: 12px;
    top: 5px;
    font-size: 18px;
    letter-spacing: 1px;
}

.tgss-dark .title span {
    position: relative;
    top: 3px;
    color: #f80758;
}

//buttons
.filled-button {
    color: white !important;
    background: #f80758 !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    line-height: 34px !important;
    border-radius: 10px;
}

.filled-button:hover {
    background: #d20348 !important;
}

.gray-filled-button {
    color: white !important;
    background: #242424 !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    line-height: 34px !important;
    border-radius: 10px;

    mat-icon {
        font-size: 17px !important;
        height: 17px !important;
    }
}

.gray-filled-button:hover {
    background: #47413dd2 !important;
}

.outline-button {
    color: #f80758 !important;
    border: 1px solid #f80758 !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    line-height: 34px !important;
    border-radius: 10px;
}

.outline-button:hover {
    background: #d20348 !important;
}

.link-btn {
    float: right;
    margin-right: 44px !important;
    color: white !important;
    background: #f80758 !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    line-height: 34px !important;
    border-radius: 10px;
}

.reorder-btn {
    float: right;
    color: white !important;
    background: #f80758 !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    line-height: 34px !important;
    border-radius: 10px;
}


.link-btn:hover {
    background: #d20348 !important;
}

.icon-btn {
    float: right;
    background: #242424 !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    line-height: 34px !important;
    border-radius: 10px;
    box-shadow: none !important;
}

.icon-btn mat-icon {
    position: relative;
    top: 8px;
    right: 6px;
}

.adv-filter-btn {
    color: white !important;
    background: #f80758 !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    line-height: 34px !important;
    border-radius: 10px;
}

.adv-filter-btn:hover {
    background: #d20348 !important;
}

.adv-reset-btn {
    background-color: #242424 !important;
}

.adv-reset-btn:hover {
    background: #47413dd2 !important;
}

.adv-reset-btn i {
    font-size: 18px;
}

//status
.status-label {
    line-height: 22px !important;
    letter-spacing: 0.3px !important;
    padding: 5px 12px 2px !important;
    border-radius: 12px !important;
    font-weight: 500 !important;
    font-size: 10px !important;
    text-transform: uppercase !important;
}

td label.mat-chip {
    line-height: 22px !important;
    letter-spacing: 0.3px !important;
    padding: 0px 12px !important;
    border-radius: 12px !important;
    font-weight: 500 !important;
    font-size: 10px !important;
    text-transform: uppercase !important;
}

.active-status {
    color: #fff !important;
    background: #058ADC !important;
    line-height: 22px !important;
    letter-spacing: 0.3px !important;
    padding: 0 12px !important;
    border-radius: 12px !important;
    font-weight: 500 !important;
    font-size: 10px !important;
    text-transform: uppercase;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.inactive-status {
    color: #fff !important;
    background: #DD2D2D !important;
    line-height: 22px !important;
    letter-spacing: 0.3px !important;
    padding: 0 12px !important;
    border-radius: 12px !important;
    font-weight: 500 !important;
    font-size: 10px !important;
    text-transform: uppercase;
}

.live-status {
    background: #29c120 !important;
    color: white !important;
    padding: 3px 12px !important;
}

.rejected-status {
    background: #e9445c !important;
    color: white !important;
    padding: 3px 12px !important;
}

.cancelled-status {
    background: #e21533 !important;
    color: white !important;
    padding: 3px 12px !important;
}

.approval-status {
    background-color: #d7801f !important;
    color: white !important;
    padding: 3px 12px !important;
}

.approved-status {
    background: #008ffb !important;
    color: white !important;
    padding: 3px 12px !important;
}

.completed-status {
    background-color: #77d571 !important;
    color: white !important;
    padding: 3px 12px !important;

}
.pending{
    background-color: #ff2205 !important;
    color: white !important;
    padding: 3px 12px !important;
}
.in-progress{
    background-color: #8b6a07 !important;
    color: white !important;
    padding: 3px 12px !important;
}

.completed{
    background-color: #0e7409 !important;
    color: white !important;
    padding: 3px 12px !important;
}
.white_spnw {
    white-space: nowrap;
}

.text_trim {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 150px;
}
.scroll_apply{
    overflow-x: scroll !important;
}
.side_nav_icon {
    display: none !important;
}

//notification side nav

.notify_sec{
    text-align: right !important;
    padding-right: 22px;
    color: #528dd2;
    text-decoration: underline;

}

//Form field
.tgss-dark .mat-datepicker-content {
    background-color: #1f1f1f;
}

.tgss-dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: black;
}

.tgss-dark .mat-calendar-body-today {
    background-color: black !important;
}

.tgss-dark .mat-select-panel {
    background: #1f1f1f;
}

.upload-file {
    border: 1px dashed grey;
    border-radius: 10px;
    padding: 25px 20px;
    // margin-top: 12px;
    cursor: pointer;
}

.upload-file-error {
    border: 1px dashed red;
    border-radius: 10px;
    padding: 25px 20px;
    // margin-top: 12px;
    cursor: pointer;
}

.upload-file:hover {
    border: 1px dashed white;
}

.upload-file-error:hover {
    border: 1px dashed red;
}

.tooltip-red {
    background-color: #70707054 !important;
}

.mat-chip-list-wrapper {
    align-items: flex-start !important;
}

.tgss-dark .mat-chip.mat-standard-chip {
    background-color: #333232f7;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tgss-dark .mat-chip.mat-standard-chip mat-icon {
    margin-left: 10px;
    cursor: pointer;
}

.mat-form-field-hide-placeholder .mat-select-placeholder {
    color: #bdbdbd !important;
    -webkit-text-fill-color: #bdbdbd !important;
    letter-spacing: normal !important;
}

.logo-name {
    font-size: 26px;
    font-weight: bold;
    color: #fff;
    margin-right: 45px;
}

mat-label.input-label {
    font-family: Archivo !important;
}

app-payment-transacion-report .sort_right_align .mat-sort-header-container {
    display: flex;
    cursor: pointer;
    letter-spacing: normal;
    outline: 0;
    justify-content: flex-end;
    padding-right: 20px;
}

.p-34 {
    text-align: right !important;
    padding-right: 34px !important;
}

//common
.mr-3 {
    margin-right: 3px !important;
}

.mr-6 {
    margin-right: 6px !important;
}

.pl-6 {
    padding-left: 6% !important;
}

.pr-2 {
    padding-right: 2% !important;
}

.pt_4 {
    padding-top: 4px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mb-18 {
    margin-bottom: 18px !important;
}

.ml-6 {
    margin-left: 6px !important;
}

.mb-6 {
    margin-bottom: 6px !important;
}

.pr-46 {
    padding-right: 46px !important;
}

.pr-40 {
    padding-right: 40px !important;
}

.pr-10 {
    padding-right: 10% !important;
}

.pr-52 {
    padding-right: 52px !important;
}

.pr-60 {
    padding-right: 60px !important;
}

.pr-20 {
    padding-right: 20px !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.overflow-unset {
    overflow: unset !important;
}

.page-content {
    margin-top: 40px !important;
}

.header-mat-menu+* .mat-menu-panel {
    min-height: 38px;
}

.mat-menu-item {
    height: 36px !important;
    line-height: 28px !important;
}

.mat-menu-content:not(:empty) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}


.mat-menu-item {
    line-height: 0 !important;
}

.Checkbox-section .mat-checkbox-layout {
    margin-right: 30px;
}

.tgss-dark .mat-form-field-appearance-fill .mat-form-field-flex {
    background: #1e1e1e !important;
    border: 1px solid #464646 !important;
    border-radius: 10px !important;
    line-height: 4px !important;
    padding: 0 16px !important;
}

.required {
    color: red;
    margin-left: 2px;
}

.tgss-dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.tgss-dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #f80758;
}

.mat-select-value-text {
    letter-spacing: normal;
}

.mat-form-field-suffix {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.tgss-dark .mat-paginator {
    background: #1a1a1a !important;
}

app-place-create .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper,
app-place-edit .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    padding: 0 !important;
}

app-place-create .mat-form-field-label,
app-place-edit .mat-form-field-label {
    letter-spacing: 0 !important;
}

app-ticket-category-create .mat-form-field-infix,
app-ticket-category-update .mat-form-field-infix {
    display: flex;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
    cursor: pointer;
}

//status

.searchbar .mat-form-field-wrapper {
    margin-right: 18px;
}

.tgss-dark .mat-menu-panel {
    background: #353333;
    min-height: 35px;
}

// upload-image

.upload-view {
    width: 100px;
    height: 100px;
    position: relative;
    margin-top: 20px;

    .close {
        position: absolute;
        right: -23px;
        background: white;
        color: black;
        width: 18px;
        height: 18px;
        top: -8%;
        border-radius: 50%;

        mat-icon {
            font-size: 18px;
        }
    }

    img {
        width: 100px;
        height: 100px;
        border: 1px solid;
        border-radius: 5px;
    }
}

//Success Toaster
.Success-popup-class {
    background: #017522 !important;
}

.Success-popup-class .swal2-success {
    color: #fff !important;
}

.Success-popup-class .swal2-success-circular-line-left {
    background-color: transparent !important;
}

.Success-popup-class .swal2-success-circular-line-right {
    background-color: transparent !important;
}

.Success-popup-class .swal2-success-fix {
    background-color: transparent !important;
}

.Success-popup-class .swal2-success-line-long {
    background-color: #fff !important;
}

.Success-popup-class .swal2-success-line-tip {
    background-color: #fff !important;
}

.Success-popup-class .swal2-success-ring {
    border: 0.25em solid #ffffffb5 !important;
}

.Success-popup-class .swal2-html-container {
    color: #fff !important;
    margin-left: 5px;
}

.Success-popup-class {
    padding: 0.325em 0.625em !important;
    box-shadow: 0 0 0.625em transparent !important;
}

//Error Toaster
.Error-popup-class {
    background: #9c0303 !important;
}

.Error-popup-class .swal2-error {
    color: #fff !important;
    border-color: #fff !important;
}

.Error-popup-class .swal2-x-mark-line-left {
    background-color: #fff !important;
}

.Error-popup-class .swal2-x-mark-line-right {
    background-color: #fff !important;
}

.Error-popup-class .swal2-html-container {
    color: #fff !important;
    margin-left: 5px;
}

.Error-popup-class {
    padding: 0.325em 0.625em !important;
    box-shadow: 0 0 0.625em transparent !important;
}

//warning
.warning-popup-class {
    background: #F8A607 !important;
}

.warning-popup-class .swal2-icon.swal2-warning {
    color: #fff !important;
    border-color: #fff !important;
}

.warning-popup-class .swal2-icon-content {
    color: #fff !important;
}

.warning-popup-class .swal2-html-container {
    color: #fff !important;
    margin-left: 5px;
}

.warning-popup-class {
    padding: 0.325em 0.625em !important;
    box-shadow: 0 0 0.625em transparent !important;
}

// Venue owner view page

.details {
    display: flex !important;
    align-items: baseline;
    padding-bottom: 18px;

    label {
        font-size: 16px;
        font-weight: 500;
        color: #ffff;
    }

    p {
        margin: 0;
        font-size: 16px;
    }
}

.upload-pic {
    display: flex;
    flex-direction: column;

    label {
        font-size: 16px;
        font-weight: 500;
        color: #ffff;
        padding-bottom: 12px;
    }

    img {
        height: 200px;
        width: 200px;
        border-radius: 20px;
        object-fit: cover;
    }
}

.example-section .mat-checkbox-layout {
    margin-right: 30px;
}



// Commission Charge

.commission-card {
    background: #1e1e1e !important;
    border-radius: 5px !important;
    height: 250px;
    overflow: auto;

    .commission {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 0.5px solid #313131;

        p {
            margin: 0;
            color: #fff;
        }

        mat-icon {
            font-size: 20px !important;
        }
    }

    .commission:hover {
        background: #363636;
        cursor: pointer;
    }

}

.add_icon_delete {
    color: palevioletred !important;
}

.add-icon {
    color: #04acfb;
}

.add-icon:hover {
    cursor: pointer;
    color: #103d8ff5;
}

.popup-container {
    .mat-dialog-container {
        padding: 0;
        border-radius: 18px;
        background: #1e1e1e !important;

        .diolog-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;

            h3 {
                margin: 0;
            }

            .close-icon {
                cursor: pointer;
            }
        }

        .content {
            padding: 20px;
        }
    }
}

//  Rating & reviews View page

app-view {
    .user-img {
        img {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            object-fit: cover;
            // object-position: top;
        }
    }

    .user-details {
        margin-left: 20px;

        h4 {
            font-size: 26px;
        }

        p {
            margin: 0;
            font-size: 16px;
            font-weight: 500;
        }
    }

    mat-icon {
        color: gray;
    }
}


/************** Custom Select ********/
.mycustom-select .mat-form-field-infix {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.d-flex-im {
    display: flex !important;
}

.my-custom-chip mat-icon {
    cursor: pointer;
    padding-left: 3px;
}

@media screen and (max-width:1400px) {
    .tgss-dark td.mat-cell {
        white-space: nowrap;
        padding: 10px;
    }

    .tgss-dark mat-card.content-card {
        overflow: auto;
    }

    .tgss-dark th.mat-header-cell {
        padding: 10px;
        white-space: nowrap;
    }
}

/************** Notes ********/
.notes {
    background: #312e2e;
    padding: 10px 15px;
    border-radius: 20px;
    color: #c2c2c2;

    label {
        font-size: 20px;
        font-weight: 500;
    }

    p {
        margin: 0;
        font-size: 13px;
    }
}

// Venue owner events view page
.event-view {
    .event-detail {
        width: 80%;

        h3 {
            font-size: 24px;
            margin-bottom: 20px;
        }

        .event-status {
            float: right;

            .live {
                background: #137613bf;
                padding: 4px 20px;
                border-radius: 4px;
                font-size: 16px;
                font-weight: 500;
            }
        }

        p {
            margin: 0;
        }
    }

    .location {
        label {
            font-size: 18px;
            font-weight: 500;
            color: #ffff;
            padding-bottom: 12px;
        }

        p {
            color: lightgray;
        }
    }

    .banner-img {
        display: flex;
        flex-direction: column;

        label {
            font-size: 16px;
            font-weight: 500;
            color: #ffff;
            padding-bottom: 12px;
        }

        img {
            width: 100%;
            height: 300px;
            object-fit: cover;
            // object-position: top left;
            border-radius: 20px;
        }
    }

    .thumb-img {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 20%;

        label {
            font-size: 16px;
            font-weight: 500;
            color: #ffff;
            padding-bottom: 12px;
        }

        img {
            border-radius: 6px;
            width: 120px;
            height: 120px;
        }
    }

    .artists {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 7px 0;

        img {
            width: 120px !important;
            height: 120px !important;
            border-radius: 50%;
            object-fit: cover;
        }

        p {
            margin: 0;
            padding-top: 6px;
        }
    }
}

// text trim

.trim-text {
    width: 175px !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
}


// owl Carousel
.owl-stage {
    display: flex;
}

.owl-nav {
    position: relative;

    .owl-prev {
        position: absolute;
        left: -15px;
        bottom: 70px;
        background: #404040 !important;
        font-size: 24px !important;
        font-weight: 300 !important;
        padding: 1px 10px !important;
        line-height: 32px !important;
        border-radius: 20px !important;
    }

    .owl-next {
        position: absolute;
        right: -15px;
        bottom: 70px;
        background: #404040 !important;
        font-size: 24px !important;
        font-weight: 300 !important;
        padding: 1px 10px !important;
        line-height: 32px !important;
        border-radius: 20px !important;
    }
}

.otp .mat-form-field-flex .mat-form-field-suffix {
    top: unset !important;
}

app-place-view {
    .owl-prev {
        position: absolute !important;
        bottom: 45px !important;
    }

    .owl-next {
        position: absolute !important;
        bottom: 45px !important;
    }
}

@media screen and (max-width:959px) {
    .side_nav_icon {
        display: unset !important;
    }
}

.event-none {
    pointer-events: none !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 8px 0 1em 0 !important;
}

.view_link {
    color: #04acfb !important;
    cursor: pointer;
}

.owl-prev::before {
    content: url('/assets/images/left.png');
    position: relative;
    top: 4px;
    right: 2px;
}

.owl-next::before {
    content: url('/assets/images/right.png');
    position: relative;
    top: 4px;
    left: 2px;
}

.owl-centered .owl-wrapper {
    display: table !important;
}

.owl-centered .owl-item {
    display: table-cell;
    float: none;
    vertical-align: middle;
    width: 193px !important;
}

.owl-centered .owl-item>div {
    text-align: left;
}

mat-ink-bar.mat-ink-bar {
    background-color: #f80758 !important;
}

.sun-editor {

    [data-command="showBlocks"],
    [data-command="codeView"],
    [data-command="print"],
    [data-command="video"],
    [data-command="paragraphStyle"],
    [data-command="formatBlock"] {
        display: none !important;
    }
}

app-place-create input::-webkit-outer-spin-button,
app-place-create input::-webkit-inner-spin-button,
app-place-edit input::-webkit-outer-spin-button,
app-place-edit input::-webkit-inner-spin-button,
app-event-create input::-webkit-outer-spin-button,
app-event-create input::-webkit-inner-spin-button,
app-event-edit input::-webkit-outer-spin-button,
app-event-edit input::-webkit-inner-spin-button,
app-venue-owner-create input::-webkit-outer-spin-button,
app-venue-owner-create input::-webkit-inner-spin-button,
app-venue-owner-update input::-webkit-outer-spin-button,
app-venue-owner-update input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

app-place-create input[type=number],
app-place-edit input[type=number],
app-venue-owner-create input[type=number],
app-venue-owner-update input[type=number],
app-event-create input[type=number],
app-event-edit input[type=number] {
    -moz-appearance: textfield;
}

app-friends-list .mat-chip-list-wrapper {
    display: unset !important;
}

.popup-container .mat-dialog-container .popup {
    height: 320px !important;
    overflow-y: scroll !important;
}

app-friends-list .mat-chip-list-wrapper {
    margin: 0 !important;
}

app-create .mat-form-field-infix {
    display: flex !important;
}

// Full Calendar
.fc-day-today {
    background-color: unset !important;
}

table tr td :last-child(1) {
    background-color: red !important;
}

.fc-day-today .fc-daygrid-day-number {
    background: transparent !important;
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
}

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
    border: none !important;
    height: 50px !important;
    min-height: 20px !important;
}

.fc-daygrid-day-top {
    justify-content: center;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    display: none;
}

.fc td,
.fc th {
    vertical-align: middle !important;
}

.fc .fc-daygrid-day-events {
    margin-top: -3px !important;
}

.fc .fc-daygrid-day-frame {
    min-height: 20% !important;
}

.fc-scrollgrid-sync-table {
    height: 250px !important;
}

.fc-view-harness-active {
    height: 350.148px !important;
}

.fc .fc-scrollgrid {
    border: none !important;
}

.fc-event-title {
    white-space: break-spaces !important;
font-weight: 500 !important;
}

.fc-prev-button,
.fc-next-button {
    background: none !important;
    color: gray !important;
    border-radius: 50% !important;
    padding: 2px !important;
    border: 1px solid gray !important;
}

.fc-theme-standard .fc-popover {
    left: 60px !important;
    // border: 1px solid gray !important;
}

.fc-daygrid-event {
    // border-color: #f80759 !important;
    // background-color: #f80759 !important;
}

.fc .fc-more-popover .fc-popover-body {
    background: #000 !important;
}

.fc-theme-standard .fc-popover-header {
    background: #3a3a3a !important;
}

.fc .fc-popover-title {
    color: #fff !important;
}

.fc .fc-popover-close {
    color: #ffffff !important;
}

.fc .fc-daygrid-more-link {
    font-size: smaller
}

.fc .fc-daygrid-day-number{
    z-index: 10 !important;
}

.fc-daygrid-more-link::before {
    content: "";
    width: 30px;
    height: 30px;
    border-radius: 50%;
    z-index: -25;
    background: #008ffb;
    position: absolute;
    top: -33px;
    left: 5px;
}


.fc-daygrid-event-dot{
display: none !important;
}
.fc-day-past a{
    color: #686868;
}
.fc .fc-day-disabled{
background: #1A1A1A !important;
}

// .fc .fc-daygrid-day-number{
//     width: 30px;
//     height: 30px;
//     border: 1px solid;
//     border-radius: 50%;
//     text-align: center;
// }


// .owl-item{
//     width: 192.833px !important;
// }
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
    border-top-color: unset !important;
}

.mat-placeholder-required.mat-form-field-required-marker {
    display: none;
}

app-place .mat-tab-label:focus {
    background-color: transparent !important;
}

app-events .mat-select-arrow {
    margin-top: 6px !important;
}

.mat-tab-label-active {
    background-color: transparent !important;
    color: white !important;
}

.event_change .owl-theme .owl-nav {
    margin-top: 30px;
}


.scroll_hide .fc-scroller{
    overflow: hidden !important;
}
.h-430{
    height: 430px !important;
}
@media screen and (max-width:1400px) {
    .event-view .thumb-img {
        width: 26%;
    }
  
}

@media screen and (min-width:1900px) and (max-width:2200px) {
    .event-view .thumb-img {
        width: 15%;
    }
}

@media screen and (min-width:2200px) {
    .event-view .thumb-img {
        width: 11%;
    }
}

@media screen and (min-width:1200px) and (max-width:1300px) {
    .fs_size span{
        font-size: 12px !important;
    }
}
@media screen and (min-width:1000px) and (max-width:1500px) {
    .owl-centered .owl-item {
        width: 311px !important;
    }
}

@media screen and (max-width:1000px) {
    .owl-centered .owl-item {
        width: 264px !important;
    }
}

@media screen and (max-width:1370px) {
    .fc .fc-daygrid-more-link{
        left: 37px;
    }
}

@media screen and (min-width:1200px) and (max-width:1300px) {
    .fc-daygrid-day-top {   
        justify-content: center;
        margin-right: 14px;
    }
    .fc-daygrid-more-link::before{
        content: "";
        position: absolute;
        top: -33px;
        left: 9px;
    }
    .fc .fc-daygrid-day-bottom{
        margin-left: -49px !important;
    }
}
@media screen and (min-width:1300px) and (max-width:1350px) {
    .fc-daygrid-day-top {   
        justify-content: center;
        margin-right: 19px;
    }
    .fc-daygrid-more-link::before{
        content: "";
        position: absolute;
        top: -33px;
        left: 9px;
    }
    .fc .fc-daygrid-day-bottom{
        margin-left: -49px !important;
    }
}
@media screen and (max-width:1025px) {
    .fc .fc-daygrid-more-link{
        left: 23px;
    }
    .fc-daygrid-day-top {   
        justify-content: center;
        margin-right: 12px;
    }
}
@media screen and (max-width:1000px) {
    .fc-daygrid-day-top {
        justify-content: center;
        margin-right: 28px;
    }
}
@media screen and (max-width:850px) {
    .fc-daygrid-day-top {
        justify-content: center;
        margin-right: 18px;
    }
}
   

@media screen and (max-width:800px) {
    .fc-daygrid-day-top {
        justify-content: center;
        margin-right: 5px;
    }
}
@media screen and (max-width:769px) {
    .fc .fc-daygrid-more-link{
        left: 27px;
    }
 
}


